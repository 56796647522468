import React, { FC } from 'react';

import { Shape } from 'components/atoms/Shape/Shape';
import { StyledContainer } from './Shapes.styles';

export const Shapes: FC = () => (
  <StyledContainer>
    <Shape
      src="/shapes/Liki_in_numbers_up.png"
      width={544}
      height={360}
      position="relative"
      left="150px"
      top="-140px"
    />
    <Shape
      src="/shapes/Liki_in_numbers_left_corner.png"
      width={80}
      height={80}
      position="relative"
      left="45px"
      top="30px"
    />
    <Shape
      src="/shapes/Liki_in_numbers_hex_right_down_corner.svg"
      width={322}
      height={243}
      position="absolute"
      right="0"
      bottom="0"
    />
    <Shape
      src="/shapes/Liki_in_numbers_ball_right_down_corner.png"
      width={48}
      height={48}
      position="absolute"
      right="40px"
      bottom="-15px"
    />
  </StyledContainer>
);
