import styled from 'styled-components';
import { motion } from 'framer-motion';

import { CounterProps } from './Counter';

export const StyledCounter = styled(motion.div)<{
  variant?: CounterProps['variant'];
  isSmallMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ variant, isSmallMobile }) => (variant === 'home' && isSmallMobile ? 'flex-end' : 'center')};
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
