import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledCounterSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'counter'
    'text-box';
  word-break: break-word;
  position: relative;

  ${mq.large`
    grid-template-rows: 80px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${({ theme }) => theme.spacing(9)};
    grid-template-areas:
      '. counter'
      'text-box counter';
    margin-top: ${({ theme }) => theme.spacing(-9)};
  `}

  ${mq.xLarge`
    grid-template-rows: 100px 1fr;
    margin-top: ${({ theme }) => theme.spacing(5)};
  `}
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  grid-area: text-box;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
`;

export const StyledInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(9, 0)};
  margin: 0 auto;
  justify-content: center;

  ${mq.large`
    margin: 0;
    min-height: 474px;
    width: 100%;
    max-width: calc(${({ theme }) => theme.sizes.layoutWidth} / 2);
    padding: ${({ theme }) => theme.spacing(0, 12, 12, 0)};
  `}
`;

export const StyledContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
export const StyledCountersWrapper = styled.div`
  grid-area: counter;
  display: flex;

  ${mq.xxsLarge`
    padding-left: ${({ theme }) => theme.spacing(3)};
  `}
`;

export const StyledCountersContainer = styled.div`
  display: grid;
  justify-content: center;
  flex: 1;
  width: 100%;
  background: ${({ theme }) => theme.gradients.primary};

  ${mq.large`
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: ${({ theme }) => theme.spacing(9)};
    grid-template-areas:
      '.'
      'counters';
    clip-path: polygon(0 185px, 100% 0, 100% 100%, 130px 100%);
    padding-left: ${({ theme }) => theme.spacing(10)};
  `}

  ${mq.xsLarge`
    padding-left: ${({ theme }) => theme.spacing(27)};
  `}

  ${mq.xLarge`
    clip-path: polygon(0px 185px, 100% 0, 100% 100%, 125px 100%);
    grid-template-rows: 100px 1fr;
  `}

  ${mq.xxsLarge`
    grid-template-rows: 150px 1fr;
  `}
`;

export const StyledCountersBox = styled.div`
  display: flex;
  grid-area: counters;
  justify-content: flex-end;
  margin: 0 auto;

  ${mq.large`
    max-width: 433px;
  `}

  ${mq.xsLarge`
    max-width: 580px;
    margin: 0;
  `}
`;

export const StyledCounters = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  align-self: center;
  grid-row-gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(9, 0)};
  white-space: nowrap;

  ${mq.xSmall`
    grid-column-gap: ${({ theme }) => theme.spacing(9)};
  `}

  ${mq.large`
    grid-column-gap: ${({ theme }) => theme.spacing(13)};
    padding: ${({ theme }) => theme.spacing(6, 0, 2)};
    align-self: flex-start;
  `}
`;
