import React, { FC } from 'react';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Counter } from 'components/Counter/Counter';

import {
  StyledCounterSection,
  StyledInfoContainer,
  StyledInfoBox,
  StyledContentWrapper,
  StyledCountersWrapper,
  StyledCountersContainer,
  StyledCountersBox,
  StyledCounters
} from './CountersSection.styles';
import { Shapes } from './Shapes/Shapes';

export type CountersSectionProps = {
  title: string;
  description: string;
  content?: string;
  counters: Array<{
    id: number;
    title: string;
    count: number;
  }>;
};

export const CountersSection: FC<CountersSectionProps> = ({ title, description, counters }) => (
  <StyledCounterSection>
    <StyledInfoContainer>
      <StyledInfoBox>
        <Container>
          <Typography variant="h2" renderAs="h2" weight="700">
            {title}
          </Typography>
          <StyledContentWrapper>
            <Typography variant="body2" weight="400">
              {description}
            </Typography>
          </StyledContentWrapper>
        </Container>
      </StyledInfoBox>
    </StyledInfoContainer>
    <StyledCountersWrapper>
      <Shapes />
      <StyledCountersContainer>
        <StyledCountersBox>
          <Container>
            <StyledCounters>
              {counters.map(({ title, count, id }) => (
                <Counter key={id} title={title} count={count} variant="home" isHomePage />
              ))}
            </StyledCounters>
          </Container>
        </StyledCountersBox>
      </StyledCountersContainer>
    </StyledCountersWrapper>
  </StyledCounterSection>
);
