import styled from 'styled-components';
import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;

  ${mq.xmLarge`
    display: block;
    width: calc(100% / 2);
    position: absolute;
  `}
`;
